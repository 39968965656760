.appt-book {
  &-location-selector {
    .location-select {
      width: 775px;
    }
  }
  .appointment-select {
    .appointments-container {
      .error-messages {
        color: $color-pink;
      }
      .confirm-container {
        .total-time {
          width: 41%;
        }
      }
    }
  }
  .confirmation {
    .confirmation-container {
      .artist {
        h4,
        .artist-text,
        .artist-name {
          display: none;
        }
      }
    }
  }
  .my-appointments {
    .appointment-container {
      .appointment-details {
        .artist {
          h4,
          .artist-text,
          .artist-name {
            display: none;
          }
        }
      }
    }
  }
}
