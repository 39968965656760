.store-locator--desktop {
  .store-locator__hero {
    #store-search-controls {
      .search-submit {
        height: 32px;
        margin: -3px 0 0 5px;
      }
      a.selectbox {
        text-align: left;
        display: inline-block;
        width: 220px;
        float: left;
        height: 32px;
        line-height: 32px;
        margin: 0 10px 0 5px;
      }
    }
  }
}
