.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

.clearfix:after {
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

select::-ms-expand {
  display: none;
}

.site-header {
  .lpStaticButton {
    width: auto;
    margin: 0px;
    td {
      border: none;
      padding: 0px;
    }
    .lpPoweredBy,
    .lpEmtStarRating {
      display: none;
    }
    a img {
      position: relative;
    }
  }
}

.site-footer {
  font-family: $brandon-text;
  .site-footer__column {
    padding: 0 15px;
  }
  .site-footer__column:nth-child(2) .menu__item {
    font-family: $brandon-text-medium;
  }
}

body {
  font-family: $brandon-text;
}

.account-profile {
  .section-header {
    &__link {
      font-family: $brandon-text;
    }
  }
  .section-content {
    font-family: $brandon-text;
  }
}

.account-orders {
  .section-header {
    &__link {
      font-family: $brandon-text;
    }
  }
  .section-content {
    font-family: $brandon-text;
  }
}

.account-address {
  .section-header {
    &__link {
      font-family: $brandon-text;
    }
  }
  .section-content {
    font-family: $brandon-text;
  }
}

.account-payment {
  .section-header {
    &__link {
      font-family: $brandon-text;
    }
  }
  .section-content {
    font-family: $brandon-text;
  }
}

.account-loyalty {
  .section-header {
    &__link {
      font-family: $brandon-text;
    }
  }
  .section-content {
    font-family: $brandon-text;
  }
}

.h6 {
  font-family: $brandon-text;
}

.cart_overlay {
  .cart-confirm-wrapper {
    .cart-products {
      .prod {
        padding: 17px 0;
        .prod-info {
          max-width: 150px;
        }
      }
    }
    .subtotal {
      .right-copy {
        .sub_t {
          width: 125px;
        }
      }
    }
  }
  .full_view {
    .subtotal {
      .sub_t {
        .sub_num {
          float: left;
          margin-left: 27px;
        }
      }
    }
  }
}

.customer-service-section {
  #landing-email {
    background: none;
    border-bottom: 1px solid #d1d2d1;
  }
  #landing-faqs {
    border-right: 1px solid #d1d2d1;
    border-top: none;
  }
  #landing-call {
    border-bottom: 1px solid #d1d2d1;
  }
  @media (max-width: 768px) {
    .customer-service-landing-block {
      a {
        bottom: 40px;
      }
    }
  }
}

.customer-service-menu {
  @media (max-width: 768px) {
    padding: 0 14px 0px 13px;
    .menu__link--lvl-1 {
      word-wrap: break-word;
    }
  }
}

.product {
  &__flag {
    display: none;
  }
}

@media (max-width: 767px) {
  .product--full {
    .product__social {
      position: relative;
      text-align: left;
    }
  }
}

.page-header__inner {
  .page-header__text {
    .page-header__subtitle--small {
      text-transform: none;
    }
  }
}

.page-header__inner {
  .page-header__text {
    .page-header__subtitle {
      text-transform: none;
    }
  }
}
/* ----------- POWER REVIEWS -------------- */
#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-helpful {
        &-yes {
          .pr-helpful-count {
            &:before {
              content: 'yardımcı oldu';
              font-size: 12px;
            }
          }
        }
        &-no {
          .pr-helpful-count {
            &:before {
              content: 'yardımcı olmadı';
              font-size: 12px;
            }
            &:after {
              display: none;
            }
          }
        }
      }
      .pr-rd-flag-review-container {
        .pr-flag-icon {
          margin-left: -4px;
        }
      }
    }
  }
}

#pr-war-form {
  .pr-ciltsorununuz-form-group,
  .pr-ürününfaydaları-form-group {
    .pr-control-label {
      float: left !important;
      margin-right: 20px;
    }
  }
}
