.tiny-waitlist-overlay {
  .email_label {
    margin-bottom: 5px;
  }
  .email_input {
    input[type='text'] {
      width: 300px;
      margin-bottom: 10px;
    }
  }
  h2 {
    margin: 0 0 15px 0;
    text-align: center;
  }
  p.waitlist_message {
    margin-bottom: 10px;
  }
  .error_messages.error li {
    margin-left: 0px;
  }
  .waitlist_header {
    text-align: left;
  }
  .field {
    border-top: 1px solid gray;
    .email_input {
      margin-top: 10px;
      display: inline-block;
    }
    .align-r {
      display: inline-block;
    }
  }
}

.waitlist_thankyou_message {
  font-family: $brandon-text-bold;
  margin: 58px 60px 90px 115px;
  line-height: 1.45em;
}

.sticky-offer {
  background: white;
}

html.ie8 .column.right.checkout__sidebar {
  display: table;
}

.product-palette__details-group-shade {
  margin: 0px 10px 10px 5px;
}

.utq_banner {
  margin-top: 20px;
}
