#lpChat {
  .lp_action_items_wrapper {
    .lp_action_wrapper {
      .lp_title {
        text-transform: none;
      }
    }
  }
  .lp_radio_button label::before,
  .lp_radio_button label::after {
    display: none;
  }
}
