/* Product Store Check */
.product--full {
  .product__actions {
    padding: 30px 0 30px;
  }
}

.product-full__store-check {
  .product-store-check--v2 {
    .instore-inventory-container {
      margin-top: 15px;
      float: inherit;
    }
    .product-store-check {
      &__inline--v2 {
        display: block;
        .inline-container {
          border-bottom: none;
          border-top: none;
          padding: 14px 0 0 0;
          @include breakpoint(($medium-only) (orientation portrait)) {
            width: 100%;
          }
          .inline-errors {
            color: $color-red;
            font-size: 14px;
          }
          .edit-controls {
            display: block;
          }
          &__doors {
            .doors-container {
              .container-row__doors {
                .door-row {
                  .door-info__avail {
                    padding-#{$ldirection}: 1em;
                    .avail-info__msg {
                      width: 25%;
                      @include breakpoint($medium-landscape-only) {
                        width: 40%;
                      }
                      &--text {
                        font-size: 14px;
                      }
                      &--span {
                        @include swap_direction(margin, 0.15em 0.25em 0 0);
                        display: inline-block;
                        border-radius: 50%;
                        height: 0.75em;
                        width: 0.75em;
                        padding: 0.2em;
                        &.msg-span {
                          &__avail {
                            background-color: $color-green;
                          }
                          &__not-avail {
                            background-color: $color-red;
                          }
                          &__call {
                            background-color: $color-orange;
                          }
                        }
                      }
                    }
                    .door-info__address {
                      @include breakpoint($medium-landscape-only) {
                        width: 50%;
                      }
                      &--distance {
                        width: 20%;
                      }
                      &--name {
                        width: 30%;
                        padding-#{$rdirection}: 10px;
                      }
                      &--phone {
                        width: 30%;
                      }
                    }
                  }
                }
              }
            }
          }
          &__controls {
            padding-bottom: 10px;
            .avail-info__msg--span {
              display: none;
            }
          }
          &__partner {
            padding-left: 14px;
          }
        }
      }
    }
    .location-controls {
      padding-left: 0;
      &__hdr {
        margin-#{$rdirection}: 5px;
        width: 100%;
        margin-bottom: 10px;
      }
      &.edit-controls {
        .location-controls__hdr {
          width: 100%;
          border-bottom: 1px solid $color-black;
          height: 30px;
          margin-bottom: 10px;
        }
      }
      &__zip {
        margin-bottom: 10px;
        &--input {
          margin-#{$rdirection}: 5px;
        }
      }
      &__btn--zip {
        height: 100%;
        line-height: 20px;
      }
      &__info {
        font-size: 11px;
        margin-#{$rdirection}: 0;
        @include breakpoint(($medium-only)) {
          width: calc(100% - 80px);
          display: block;
        }
        @include breakpoint(($max-width-medium)) {
          display: inline;
        }
      }
      &__link--edit {
        margin-#{$rdirection}: 5px;
      }
      &__distance--select {
        width: 100%;
        min-width: 70px;
        padding: 0 5px 0 0;
        position: relative;
        top: -5px;
        #{$rdirection}: 0;
        @include breakpoint(($medium-only)) {
          @include swap_direction(padding, 0px 20px 0 2px);
        }
        @include breakpoint(($large-up)) {
          @include swap_direction(padding, 0px 5px 0 2px);
        }
      }
      &__distance {
        top: 28px;
        #{$rdirection}: 10px;
        min-width: 70px;
      }
      &__input {
        height: auto;
        min-height: 30px;
        border: 1px solid $color-black;
      }
      &__close {
        color: $color-gray-dark;
        #{$rdirection}: 10px;
      }
    }
    .psc-state-init {
      .inline-container {
        &__partner {
          display: none;
        }
        &__link {
          display: block;
        }
      }
    }
  }
  .find-in-store {
    display: inline-block;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    border: 1px solid $color-black;
    padding: 5px 0;
    color: $color-black;
    text-decoration: none;
  }
}
