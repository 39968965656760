$font_updates: true;
$disable-bbv2-in-checkout: true;

// Enable Appointment Booking Reskin 2019
$ab_skin_v2: true;

$brandon-text: 'BBText', Arial, Verdana, sans-serif;
$brandon-text-light: 'BBText Light', Arial, Verdana, sans-serif;
$brandon-text-medium: 'BBText Medium', Arial, Verdana, sans-serif;
$brandon-text-bold: 'BBText Bold', Arial, Verdana, sans-serif;

$cr22: true;
