.enhanced-delivery-page {
  .pg_wrapper {
    .column.left {
      #checkout_shipping {
        .selectBox-arrow {
          &::before {
            #{$rdirection}: 15px;
            border: 5px solid transparent;
            border-top-color: $color-grey-shadow;
            bottom: 20px;
            content: '';
            pointer-events: none;
            position: absolute;
            z-index: 9999;
          }
        }
        .billing-address-form-container {
          .delivery_instructions_container,
          .delivery_instructions {
            display: none;
          }
          .form_element {
            a.selectBox {
              @include appearance(none);
              @include border-radius(0);
              @include swap_direction(padding, 17px 20px 13px 15px);
              font-size: 12px;
              height: 54px;
              margin: 0;
              width: 100%;
              .selectBox-label {
                bottom: 3px;
                color: $color-black;
                position: relative;
              }
              .selectBox-arrow {
                display: none;
              }
            }
            &.country_container {
              border: 1px solid $color-black;
              color: $color-black;
              height: 54px;
              margin-top: 22px;
              padding: 18px 15px;
              &::after {
                display: none;
              }
            }
          }
        }
        #billing-address-info-panel {
          .billing-address {
            margin: 0;
          }
        }
      }
    }
    .billing-address-form-container,
    .edit-address-container {
      .select-arrow {
        &::before {
          #{$rdirection}: 15px;
          border: 5px solid transparent;
          border-top-color: $color-grey-shadow;
          bottom: 20px;
          content: '';
          pointer-events: none;
          position: absolute;
          z-index: 999;
        }
      }
    }
  }
  &#payment {
    .column.left {
      .wrapper {
        .checkout-header {
          margin: 0;
        }
        #payment-method {
          h3 {
            display: none;
          }
        }
        .return-user__item {
          font-size: 12px;
          letter-spacing: 0.25px;
          margin: 25px 0;
          min-width: 100%;
          input[type='checkbox'] {
            #{$ldirection}: 0;
            appearance: none;
            border-radius: 2px;
            border: 2px solid $color-grey-eclipse;
            float: $ldirection;
            height: 18px;
            margin-#{$ldirection}: 15px;
            padding: 0;
            position: relative;
            width: 18px;
            &:checked {
              background-color: $color-white;
              &::before {
                #{$ldirection}: 4px;
                border: solid $color-black;
                border-width: 0 2px 2px 0;
                content: '';
                display: block;
                height: 13px;
                position: relative;
                top: -1px;
                transform: rotate(45deg);
                width: 6px;
              }
            }
          }
          label {
            display: flex;
            margin-#{$ldirection}: 50px;
            position: relative;
            &::before {
              display: none;
            }
          }
        }
        #payment-container {
          #select-payment {
            color: $color-black;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.25px;
            line-height: 1.4;
            margin: 16px 0 37px;
            text-align: center;
            text-transform: uppercase;
          }
          .cc-header {
            display: none;
          }
          .payment-type-cc {
            border-bottom: 1px solid $color-grey-border;
            border-top: 1px solid $color-grey-border;
            font-size: 12px;
            font-weight: bold;
            margin: 16px auto;
            padding: 15px 0 16px;
            text-transform: uppercase;
            width: 100%;
            label {
              font-size: 12px;
              float: $ldirection;
              line-height: 1.92;
              width: 75%;
            }
            #credit-cards {
              @include display-flex;
              li {
                padding-#{$rdirection}: 5px;
                img {
                  height: 23px;
                  width: 37px;
                }
                &:last-child {
                  padding-#{$rdirection}: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
