.section-customer-service-contact-us {
  #contact-about-content {
    .customer-service-contact-form__category {
      padding: 1em 0;
      letter-spacing: 1px;
      line-height: 40px;
      .online_product_tech_service {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
      }
    }
  }
}

.site-footer-contact {
  .site-footer-contact__item.customerservice_width {
    width: 100%;
  }
  @media (min-width: 768px) {
    .site-footer-contact__item.customerservice_width {
      width: 50%;
      a {
        margin-left: 20%;
      }
    }
  }
}

.responsive-tabs {
  .resp-tabs-list {
    .bv_ask_tab {
      display: none;
    }
  }
}

.quickshop__container {
  .product--full {
    .product__rating {
      div.t-desktop {
        display: none;
      }
      span {
        div.t-desktop {
          display: inline;
        }
      }
    }
  }
}

.product__details {
  .product__rating {
    #BVRRSummaryContainer {
      width: 100%;
      margin-top: 7px;
      margin-bottom: 20px;
    }
    #BVQASummaryContainer {
      display: none;
    }
  }
}

.js-sticky-header header.site-header {
  .js-site-header-sticky .site-utils__inner {
    @media (min-width: 768px) and (max-width: 950px) {
      padding: 0 10px;
      h2.site-utils__logo {
        width: 30%;
      }
      .site-utils__links {
        .site-nav {
          .site-header__menu-list {
            .gnav-section {
              &:first-child {
                margin-left: 20px;
              }
            }
          }
          ul.menu__list {
            padding: 10px 0;
            li.menu__item {
              float: left;
              padding: 0 11px;
              a.menu__link {
                font-size: 11px;
                letter-spacing: 0;
                line-height: 35px;
                padding: 0;
                height: 35px;
              }
            }
            .leaf.menu__item {
              width: 20%;
              &.first {
                width: auto;
                a.menu__link {
                  line-height: 35px;
                }
              }
              a.menu__link {
                line-height: 18px;
              }
            }
          }
        }
        .site-utils__right {
          width: 16%;
        }
      }
    }
  }
}

.customer-service {
  .customer-service-quick-info {
    .customer-service-quick-info__list-item {
      margin-left: 14%;
    }
  }
}

footer.site-footer {
  .site-footer-contact {
    .site-footer-contact__item--email {
      padding-left: 10px;
    }
    .site-footer-contact__item--phone {
      padding-right: 10px;
    }
  }
}

@media only screen and (min-device-width: 768px) and (orientation: portrait) {
  footer.site-footer {
    .site-footer-contact {
      .site-footer-contact__item--email {
        .site-footer-contact__link {
          margin-left: 10%;
        }
      }
      .site-footer-contact__item--phone {
        .site-footer-contact__link {
          margin-left: 15%;
        }
      }
    }
  }
}

.site-email-sms-signup {
  .site-email-sms-signup__birthday-label {
    word-wrap: break-word;
  }
  .site-email-sms-signup__agree {
    input[type='checkbox'] {
      position: absolute;
    }
  }
  .site-email-sms-signup__header {
    h1 {
      font-size: 2.1em;
    }
  }
}

.makeup-lessons {
  &__header {
    &--content h4 {
      font-size: 17px;
      font-family: Verdana;
    }
  }
}

.bobbis-lessons__slide {
  .bobbis-lessons__description {
    p {
      font-size: 15.5px;
      font-family: verdana;
    }
    p strong {
      font-family: 'Brandon Text', Arial, Verdana, sans-serif;
      font-size: 16px;
    }
  }
}

.page-header {
  &__subtitle {
    font-family: verdana;
    font-size: 13.2px;
    text-transform: lowercase;
  }
  .page-header--has-subtitle {
    .page-header {
      &__title {
        font-weight: 600;
      }
    }
  }
}

.node-elc-mpp {
  .page-header {
    .page-header__title__inner {
      font-weight: 600;
    }
  }
}

.site-header {
  .site-header {
    &__main {
      .site-header__nav .menu__list {
        .menu__list li a {
          font-family: verdana;
          font-size: 14.5px;
          color: #1b1818;
        }
      }
    }
  }
  &__container {
    .site-header-formatter {
      @include breakpoint($medium-up) {
        height: auto;
      }
      &.header-offers-banner-hidden {
        @include breakpoint($medium-up) {
          height: auto;
        }
      }
    }
  }
}

.product-thumb {
  .product-thumb__cta {
    font-stretch: semi-condensed;
  }
}

.no-touch {
  .product-thumb--animate-cta {
    .product-thumb__cta {
      font-stretch: semi-condensed;
    }
  }
}

.offers-everyday {
  &__item-header {
    font-weight: 600;
  }
}

.offers-hero__header {
  .inner {
    font-weight: 600;
  }
}

.offers-details-item__button,
a.offers-hero__button.btn {
  font-weight: 600;
}

.makeup-lessons-items {
  &__meta--description {
    color: #1b1818;
    font-size: 17px;
  }
}

.about-bobbi {
  &__story-bobbi {
    &__quote {
      font-family: verdana;
      font-size: 25px;
    }
  }
}

.about-bobbi {
  &__story-bobbi {
    &__prologue {
      font-family: verdana;
      font-size: 17px;
    }
  }
}

.about-bobbi {
  &__story-chapters {
    &__name {
      font-stretch: semi-condensed;
    }
  }
}

.about-bobbi {
  &__causes {
    &__intro p {
      font-family: verdana;
      font-size: 21px;
    }
  }
}

.offers-hero {
  &__text {
    p:first-child {
      font-stretch: semi-condensed;
    }
  }
}

.about-bobbi__causes__how-it-works {
  p {
    font-family: verdana;
    font-size: 17px;
    strong {
      font-family: 'Brandon Text', Arial, Verdana, sans-serif;
    }
  }
}

.about-bobbi__causes__partners-copy {
  p {
    font-family: verdana;
    font-size: 17px;
  }
}

.site-nav {
  .menu {
    &__link--lvl-2 {
      font-weight: 600;
    }
  }
}

.section-esearch {
  .search-form--results {
    input.search-form__submit {
      margin-#{$ldirection}: 0;
    }
    .search-form__results-count {
      line-height: 2.5;
      #{$rdirection}: 181px;
    }
  }
}

.mpp__content {
  .mpp__filters {
    .mpp__filters__headline {
      font-weight: 600;
    }
    span.product-filter__filter {
      font-weight: 600;
      font-family: arial, verdana !important;
    }
  }
}

.popup-offer__outer-wrap.popup-offer__sign-up {
  padding: 0 5px 0 0;
  h2.popup-offer__heading {
    margin-bottom: 20px;
  }
}

.mpp.gift_guide {
  .product-filter {
    width: 900px !important;
  }
}

.bobbi-glow {
  .product_highlighting-powder {
    min-height: 345px;
    background-position: center 20px;
    padding: 350px 30px 30px 0;
    border-top: 1px solid #eff0f0;
    border-bottom: 1px solid #eff0f0;
  }
}

.offer-code-panel {
  .offer_code_form_container {
    em#one-offer-only {
      span {
        float: left !important;
        a {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.mpp__content {
  .product-grid__content {
    .product__flag {
      display: initial;
    }
  }
}

.popup-offer__sign-up-form {
  .popup-offer__email-input {
    text-transform: none;
  }
}

.offer-code-panel {
  .offer-code-panel__content {
    .offer_code_form_container {
      input {
        text-transform: none;
      }
    }
  }
}

.checkout-co-enabled {
  .checkout {
    .left {
      &.checkout {
        &__content {
          .banner {
            p {
              text-align: left !important;
              margin-top: 0;
            }
          }
          #viewcart-panel {
            .cart-items {
              &.products {
                .cart-item {
                  &.product {
                    .cart-item {
                      &__total {
                        width: auto;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Hide the appointment details in canceled popup(BB TR)
#appt-canceled {
  .appointment-details {
    display: none;
  }
}
#appt-canceled {
  a {
    margin-top: 25px;
  }
}

.sign-in {
  .registration {
    &__mobile {
      width: 188px;
      margin-bottom: 10px;
      height: 45px;
    }
  }
}
