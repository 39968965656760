.error {
  color: $color-red;
}

.legal_frame {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 10rem;
  border: 1px solid $color-border-gray;
  padding: 5px;
  font-size: 90%;
  overflow-y: auto;
  word-break: break-word;
}

@media (max-width: $tablet) {
  .legal_frame {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (min-width: 768px) {
  .sign-in-page {
    .legal_frame {
      width: 75%;
    }
    &.checkout {
      input[type='submit'] {
        &.sign-in-page {
          &__submit {
            position: relative;
          }
        }
      }
      .legal_frame {
        width: 100%;
      }
    }
  }
}

.checkout-co-enabled {
  &#signin {
    .pg_wrapper {
      .sign-in-page {
        #form--errors--checkout_signin {
          margin: 20px 0 0;
        }
        @media (max-width: $tablet) {
          .return-user {
            &__item {
              .sms_input {
                width: 100%;
              }
            }
            &__legal {
              display: block;
              margin-top: 5px;
            }
          }
        }
        .return-user {
          &__item {
            clear: both;
          }
        }
      }
    }
  }
}

.section-data-privacy-statement {
  .site-header {
    display: none;
  }
  .site-footer {
    display: none;
    &__sticky {
      display: none;
    }
  }
  .LPMcontainer,
  .optanon-alert-box-wrapper,
  .popup-offer-cbox {
    display: none !important;
  }
}

.checkout {
  &__content {
    .return-user {
      &__item {
        margin-top: 10px;
      }
    }
  }
}

.error_messages {
  &.error {
    li {
      margin-left: 0;
    }
  }
}

.profile-info {
  .error_messages {
    &.error {
      li {
        margin-left: 20px;
      }
    }
  }
}

.pg_wrapper {
  &.presales-container {
    padding-top: 0;
    table {
      margin-top: 0;
    }
  }
}
