.account {
  #address_form_container {
    margin-bottom: 17px;
    .address-overlay-shipping {
      .form_element {
        margin-bottom: 6px;
        select {
          padding: 5px 10px;
        }
      }
      .title_container,
      .phone_label_container {
        margin-bottom: 10px;
      }
      input[type='text'],
      select {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    .form_element {
      margin-bottom: 6px;
      input,
      select {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  .profile-page__content {
    #profile_preferences {
      .profile-info {
        .profile-info__item.profile-info__required-container {
          float: none;
          padding: 0;
        }
      }
    }
    .profile-info__fieldset {
      .profile-info__item.title_container {
        float: none;
        width: 100%;
      }
    }
    .newsletter-info__fieldset {
      .newsletter-info__item {
        float: none;
      }
    }
    .profile-info__item {
      .password_help_text {
        padding-top: 5px;
      }
    }
  }
}

.optional-info {
  .optional-info__fieldset {
    .optional-info__item {
      float: none;
    }
  }
}

a.selectBox {
  &.error {
    border: 1px solid #ff0000;
  }
}

.order-details-page.signin-page {
  max-width: 1024px;
  margin: 40px auto;
  .account-utilities {
    float: left;
    margin-right: 32px;
  }
  .order-details-page__content {
    width: auto;
    overflow: hidden;
    padding-bottom: 1px;
    .transaction-panel-details {
      margin-top: 32px;
      .trans_detail_item {
        float: left;
        margin: 0 28px 28px 0;
        position: relative;
        width: 225px;
        word-wrap: break-word;
      }
    }
    .order-products {
      .cart-item.product {
        .cart-item__desc {
          @media (min-width: 768px) {
            width: 31%;
            float: left;
          }
        }
      }
    }
  }
}

.account__panel-title {
  font-family: $brandon-text-bold;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-indent: 0;
  margin: 4px 0 10px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid $color-black;
}

.signin-page {
  max-width: 1024px;
  margin: 40px auto;
}

.return-user__item,
.new-account__item,
.request-form__item,
.sent-info__text,
.password-reset__item {
  margin-bottom: 0.8em;
}

.password-reset__item {
  .field {
    display: block;
    width: 300px;
  }
}

.account-page {
  &__content {
    .section-header {
      &:before {
        background: none;
      }
    }
    .account-profile {
      &__newsletter {
        .newsletter_text {
          margin-bottom: 0;
        }
      }
      &__email {
        word-wrap: break-word;
      }
    }
  }
}

#address_form_container {
  .address-overlay-shipping {
    .address {
      .default_ship_container {
        float: left;
        width: 100% !important;
      }
    }
  }
  #address {
    position: relative;
  }
}

.past-purchases {
  &__shopping {
    margin: 30px 0 0 0px;
  }
}

.sample {
  .cart-item {
    &__qty-label {
      display: block;
    }
  }
}

.sms_options_container {
  .sms_promo_terms_container {
    margin-top: 10px;
  }
}

.profile-page {
  &.account {
    &__section {
      .profile-page {
        &__content {
          .newsletter-info {
            .fs {
              &.sms_promotions {
                margin-left: 20px;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.pc_email_promo_terms_container {
  margin-top: 10px;
}

.create-wishlist__name {
  width: 58%;
}

.profile-pic-upload {
  .profile-pic-upload__form-wrapper {
    .upload-file {
      .upload-file__value {
        right: 20px;
        word-wrap: break-word;
      }
    }
  }
}
