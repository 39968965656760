.change-password {
  .password-field {
    &__info {
      &-reset {
        @include breakpoint($medium-up) {
          top: 50%;
          #{$ldirection}: 220px;
          width: 190px;
        }
      }
    }
  }
  &-gnav {
    .cms-password-field {
      &__info {
        margin-#{$ldirection}: 0;
        margin-top: 0;
        &:before {
          top: 41%;
          right: 97%;
        }
      }
    }
  }
}
