///
/// @file base/_typography.scss
///
/// \brief Fonts, line-heights and re-usable text styles
///
/// Components should extend these basic styles whenever possible.
///

///
/// @font-face imports using compass mixin
///

// Mixins
@mixin tr-text-bold {
  font-weight: 600;
}

.h4,
.h8,
.h10,
.h12,
.h13 .h16,
.h19,
.h20,
.h22,
.h24,
.h25,
.brandon-text-bold,
.bold {
  @include tr-text-bold;
}

// search forms

.search-form {
  input[type='text'].search-form__field,
  input[type='submit'].search-form__submit {
    @include tr-text-bold;
  }
}

// site utilities

.site-utilities {
  @include tr-text-bold;
}

// overlay offer signup

.overlay-offer-signup {
  &__title {
    @include tr-text-bold;
  }
}

// overlay offer error

.overlay-offer-error {
  &__title {
    @include tr-text-bold;
  }
}

// overlay offer thanks

.overlay-offer-thanks {
  &__title {
    @include tr-text-bold;
  }
}

// mpp

.mpp {
  &__filters {
    &__no-matches {
      @include tr-text-bold;
    }
  }
  .product-thumb__abstract {
    .product__flag {
      @include tr-text-bold;
    }
    span.product-thumb__cta span {
      @include tr-text-bold;
    }
  }
}

.product {
  &__flag {
    @include tr-text-bold;
  }
}

//spp

.spp {
  .product {
    .product__details {
      .product__header {
        h3.product__title {
          @include tr-text-bold;
        }
      }
    }
  }
  .spp__works-with {
    h2.section-header {
      @include tr-text-bold;
    }
  }
}

//footer

.site-footer {
  &-toggle-device {
    a.toggle-mobile {
      @include tr-text-bold;
    }
  }
  .site-footer-contact {
    .site-footer-contact__menu {
      li a {
        @include tr-text-bold;
      }
    }
  }
  h3.site-email-signup__header,
  h3.site-footer-social-links__header {
    @include tr-text-bold;
  }
  h3.sticky-offer__headline {
    @include tr-text-bold;
  }
}

h3.email-signup__success-header {
  font-weight: 600 !important;
}

p.email-signup__success-text {
  @include tr-text-bold;
}

//header

.site-utils {
  &__promo {
    @include tr-text-bold;
  }
  &__link {
    @if $cr22 == false {
      @include tr-text-bold;
    }
  }
}

.site-header {
  .site-header__main {
    .site-header__nav {
      .menu__list {
        li a {
          @include tr-text-bold;
        }
        .menu__list {
          li a {
            font-weight: normal;
          }
        }
      }
    }
  }
}

// mpp quick shop

.module-mpp-quick-shop__title {
  @include tr-text-bold;
}

.module-mpp-quick-shop__more-info {
  strong {
    @include tr-text-bold;
  }
}

.quickshop {
  .product {
    .product__details {
      h3.product__title a {
        @include tr-text-bold;
      }
      .product__actions {
        .product__full-details-link-wrapper {
          a {
            @include tr-text-bold;
          }
        }
      }
    }
  }
}

//cart confirm

.cart_overlay {
  .cart-confirm-wrapper {
    h2 {
      @include tr-text-bold;
    }
    .cart-products {
      .prod {
        .prod-info {
          a {
            @include tr-text-bold;
          }
          .price {
            @include tr-text-bold;
          }
        }
      }
    }
    .subtotal {
      @include tr-text-bold;
    }
  }
}

//about

.about-bobbi {
  &__footer {
    &__title {
      @include tr-text-bold;
    }
  }
  &__moments {
    &-item {
      &__year {
        @include tr-text-bold;
      }
    }
  }
  &__causes {
    &__title {
      @include tr-text-bold;
    }
  }
  &__story {
    &-bobbi__header {
      @include tr-text-bold;
    }
  }
}

// Buttons

button,
input[type='submit'],
input[type='button'] {
  @include tr-text-bold;
}

.link {
  @include tr-text-bold;
}

.product-fave-confirm {
  &__copy p {
    @include tr-text-bold;
  }
}

// gift cards

.giftcard {
  &__tab {
    @include tr-text-bold;
  }
  &__purchase {
    @include tr-text-bold;
  }
  &__check-balance {
    @include tr-text-bold;
  }
  &__description {
    &-header {
      @include tr-text-bold;
    }
    &-sub-text {
      @include tr-text-bold;
    }
  }
  &__step {
    &-container {
      @include tr-text-bold;
    }
  }
  &__price {
    &-option {
      @include tr-text-bold;
    }
  }
  &__guides {
    &-header {
      @include tr-text-bold;
    }
  }
}

.giftcard-balance {
  &__header {
    @include tr-text-bold;
  }
  &__purchase {
    @include tr-text-bold;
  }
}

// checkout offer promos

.promo-panel {
  .banner {
    strong,
    b {
      @include tr-text-bold;
    }
  }
}

.cart-item {
  &__desc {
    .remove-sample {
      @include tr-text-bold;
    }
  }
  &__remove-form {
    a {
      @include tr-text-bold;
    }
  }
  &__product-name {
    @include tr-text-bold;
  }
}

.replenishment__description {
  @include tr-text-bold;
}

.cart-header {
  @include tr-text-bold;
}

.product {
  .product-name {
    @include tr-text-bold;
  }
}

// account

.account__section {
  .account-utilities__section {
    ul {
      li a {
        @include tr-text-bold;
      }
      li:nth-child(2) a {
        font-weight: normal;
      }
      li:nth-child(3) a {
        font-weight: normal;
      }
    }
  }
  .account-page__content {
    .account-profile {
      .section-head {
        h3 {
          @include tr-text-bold;
        }
      }
      .section-content {
        div {
          span {
            @include tr-text-bold;
          }
        }
      }
    }
    .account-orders,
    .account-payment,
    .account-address {
      .section-header {
        h3 {
          @include tr-text-bold;
        }
      }
    }
    .account-payment,
    .account-address {
      .section-content {
        h4 {
          @include tr-text-bold;
        }
      }
    }
    h2.account-page__header {
      @include tr-text-bold;
    }
  }
  .address-book-page__content {
    h2,
    h3 {
      @include tr-text-bold;
    }
    .address-book {
      a {
        @include tr-text-bold;
      }
    }
    .payment-info {
      a {
        @include tr-text-bold;
      }
    }
  }
  .profile-page__content {
    h1,
    h3 {
      @include tr-text-bold;
    }
  }
  .past-purchases__content {
    .past-purchases-page__header {
      @include tr-text-bold;
    }
  }
  .favorites-page__content {
    .favorites-page__header,
    .favorites-page__tabs a {
      @include tr-text-bold;
    }
    section.favorites {
      a.favorites-mylists__lists-create-button {
        @include tr-text-bold;
      }
      ul.favorites__list {
        li {
          a.product-info__link {
            @include tr-text-bold;
          }
        }
      }
      .my-lists__list-actions {
        a.my-lists__list-link {
          font-weight: 600;
        }
      }
    }
    section.favorites-mylists {
      .favorites-mylists__lists {
        ul.my-lists {
          li {
            h3 {
              a {
                @include tr-text-bold;
              }
            }
          }
        }
      }
      .favorites-mylists__content {
        .favorites-mylists__lists {
          a {
            font-weight: 600;
          }
        }
      }
    }
    .favorites__instruction-step {
      .favorites__recommended-products {
        .favorites__recommended-products-slide {
          .favorites__recommended-product {
            .description {
              .product_name {
                a {
                  font-weight: 600;
                }
              }
            }
            .favorites__recommended-product-actions {
              a.favorites__recommended-product-add-to-cart {
                font-weight: 600;
              }
            }
          }
        }
      }
      .favorites__sample-list {
        .product-item {
          .product-info__actions {
            a.product-info__link {
              font-weight: 600;
            }
          }
          .product-info {
            .product-info__product {
              .product-info__name a {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
  .sign-in-page__container {
    h3.sign-in-page__header {
      @include tr-text-bold;
    }
    .account__new-account,
    .account__return-user {
      h2.section-head__header {
        @include tr-text-bold;
      }
    }
    .sign-in-page__login {
      @include tr-text-bold;
    }
  }
  section.past-purchases__content {
    .outer-wrap {
      ul.past-purchases-data-header {
        li {
          @include tr-text-bold;
        }
      }
    }
  }
}

.address-delete-confirm {
  h3.address-delete__header {
    font-weight: 600 !important;
  }
}

.password-request-page {
  .password-request-page__content {
    section.account-utilities__section {
      h3.account-utilities__header {
        @include tr-text-bold;
      }
    }
    h2.password-request-page__header {
      @include tr-text-bold;
    }
  }
}

.password-sent-page {
  section.password-sent-page__content {
    h2.password-sent-page__header {
      @include tr-text-bold;
    }
    .password-sent__text {
      .sent-info__text a {
        @include tr-text-bold;
      }
    }
  }
  section.account-utilities__section {
    h3.account-utilities__header {
      @include tr-text-bold;
    }
  }
}

.password-reset-page {
  section.password-reset-page__content {
    h2.password-reset-page__header {
      @include tr-text-bold;
    }
  }
  section.account-utilities__section {
    h3.account-utilities__header {
      @include tr-text-bold;
    }
  }
}

// store locator

.store-locator {
  .store-locator__header {
    @include tr-text-bold;
  }
  .store-locator__info-strong {
    @include tr-text-bold;
  }
  .store-locator__button {
    @include tr-text-bold;
  }
  .store-locator-section--list_item-section {
    h4 {
      @include tr-text-bold;
    }
  }
  .store-locator__tooltip {
    .store-locator__tooltip-name {
      @include tr-text-bold;
    }
  }
}

.chips {
  h3 a {
    @include tr-text-bold;
  }
}

.store-locator--desktop {
  .store-locator__locate,
  .store-locator__dutyfree {
    span.text {
      @include tr-text-bold;
    }
  }
  .store-locator__hero {
    h1 {
      font-weight: 600 !important;
    }
    span.store-locator-either-or {
      font-weight: 600 !important;
    }
  }
  .store-locator-section--list {
    .store-locator__event {
      .store-locator__event-list__item {
        h5 {
          @include tr-text-bold;
        }
      }
    }
  }
}

// customer service
.customer-service {
  .customer-service-header {
    h1.customer-service-header__title {
      font-family: 'Brandon Text Bold', Arial, Verdana, sans-serif;
      font-weight: 600;
    }
  }
  .customer-service-menu {
    ul.menu__list {
      l1 a {
        font-family: 'Brandon Text Bold', Arial, Verdana, sans-serif;
        font-weight: 600;
      }
    }
  }
  .customer-service-section {
    .customer-service-landing-block {
      h2.customer-service-landing-block__header {
        font-family: 'Brandon Text Bold', Arial, Verdana, sans-serif;
        font-weight: 600;
      }
      a.customer-service-landing-block__link {
        font-family: 'Brandon Text Bold', Arial, Verdana, sans-serif;
        font-weight: 600;
      }
    }
  }
}

.customer-service-corporate {
  .customer-service-menu {
    .menu__list {
      li a {
        font-family: 'Brandon Text Bold', Arial, Verdana, sans-serif;
        font-weight: 600;
      }
      .menu__list {
        li a {
          font-family: 'Brandon Text', Arial, Verdana, sans-serif;
          font-weight: normal;
        }
      }
    }
  }
  .customer-service-section {
    h2 {
      font-family: 'Brandon Text Bold', Arial, Verdana, sans-serif;
      font-weight: 600;
    }
  }
}

// viewcart
.viewcart {
  .checkout__content {
    .viewcart-panel {
      .viewcart-header {
        h2.viewcart-panel__title {
          font-weight: 600;
        }
      }
      .viewcart-panel__content {
        h4.checkout__subtitle {
          font-weight: 600;
        }
      }
      .viewcart-buttons-panel {
        a.continue-shopping {
          font-weight: 600;
          display: block !important;
          visibility: visible;
        }
        .continue-buttons a {
          font-weight: 600;
        }
      }
    }
    .bottom-viewcart-buttons {
      .viewcart-buttons-panel {
        .content {
          a {
            font-weight: 600;
          }
        }
      }
    }
    .shipping-page-info-panel {
      .checkout__panel-title-container {
        h2.shipping-panel__title {
          font-weight: 600;
        }
      }
      .shipping-address-info {
        h2.shipping-info-panel__title {
          font-weight: 600;
        }
      }
      .billing-info {
        h2.billing-info-panel__title {
          font-weight: 600;
        }
      }
      .delivery-info {
        .delivery-info-content {
          h2.shipping-info-panel__title {
            font-weight: 600;
          }
        }
      }
      .giftwrap-info {
        #gift-options {
          h3 {
            font-family: 'Brandon Text Bold', Arial, Verdana, sans-serif !important;
            font-weight: 600 !important;
          }
        }
      }
      #payment-method {
        h3 {
          font-family: 'Brandon Text Bold', Arial, Verdana, sans-serif !important;
          font-weight: 600 !important;
        }
      }
      .emailpromo-info {
        section.newsletter-info {
          h3.newsletter-info__header {
            font-weight: 600;
          }
        }
      }
    }
    .checkout-header {
      h2.checkout__panel-title {
        font-weight: 600;
      }
    }
    .shipping-panel {
      h2.checkout__panel-title {
        font-weight: 600;
      }
      .transaction-panel-details {
        .trans_detail_item {
          h4.checkout__subtitle {
            font-weight: 600;
          }
        }
      }
    }
    #checkout_complete {
      h3.newsletter-info__header {
        font-weight: 600;
      }
    }
    .checkout__panel-title-container {
      h2.payment-info-panel__title {
        font-weight: 600;
      }
    }
    section.confirm-panel {
      #print-order {
        a {
          font-weight: 600;
        }
      }
    }
  }
  .checkout__sidebar {
    .account-utilities__section {
      h3.account-utilities__header {
        font-weight: 600;
      }
    }
    .order-summary-panel {
      h2.order-summary-panel__title {
        font-weight: 600;
      }
      .order-summary__content {
        .tax.order-summary__tax-label {
          margin-top: 10px;
        }
      }
    }
    .links-panel {
      h2.links-panel__title {
        font-weight: 600;
      }
      ul.links_list {
        li a {
          font-weight: 600;
        }
      }
    }
    .offer-code-panel {
      h2.offer-code-panel__title {
        font-weight: 600;
      }
      .offer-code-panel__content {
        .offer_code_form_container {
          .form-submit {
            font-weight: 600;
          }
        }
      }
    }
    .return-link-container {
      a {
        font-weight: 600;
      }
    }
  }
  //samples
  .samples-panel {
    h2.samples-panel__title {
      font-weight: 600;
    }
    .checkout__content {
      #checkout_samples {
        ul.product-list {
          li {
            .details {
              .product-desc {
                display: none;
              }
              .product-name {
                font-weight: 600;
              }
              .sample-sku-list {
                li {
                  .sample-select-checkbox {
                    label {
                      font-weight: 600;
                    }
                  }
                }
              }
            }
          }
        }
        .samples-buttons.bottom {
          .return-link {
            a {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .below-columns {
    .recommended-products-panel {
      h2.recommended-products-panel__title {
        font-weight: 600;
      }
      .recommended-products-panel__content {
        .recommended-product-items {
          .recommended-item {
            .product_name {
              a {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}

// cbox

#cboxContent h3 {
  font-weight: 600;
}

// order detail page
.order-details-page {
  .order-details-page__content {
    section.order-details {
      .order-details__item {
        h4.checkout__subtitle {
          font-weight: 600;
        }
      }
    }
  }
}

// shopping bag button

a.quickshop__button {
  font-weight: 600;
}

// Mobile
.checkout {
  .samples-panel {
    h2.samples-panel__title {
      font-weight: 600;
      line-height: normal;
    }
    .samples-panel__content {
      .sample-products {
        ul.product-list {
          li {
            .details {
              .product-desc {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .checkout__content {
    .confirmation-panel__actions {
      .print-buttons {
        a {
          font-weight: 600;
        }
      }
    }
  }
}

.order-details-page__content {
  section.order-details {
    .order-details__item {
      h4.checkout__subtitle {
        font-weight: 600;
      }
    }
  }
  section.order-products {
    .cart-item {
      .cart-item__total {
        float: right;
        margin-top: 10px;
      }
    }
  }
}
