@import 'checkout-helpful-links';

.checkout {
  .left.checkout__content {
    #viewcart-panel {
      .cart-items.products {
        .cart-item.product {
          .cart-item {
            &__total {
              width: 14%;
            }
            &__price {
              width: 20%;
              .checkout-co-enabled & {
                width: 40%;
              }
            }
            &__thumb-image {
              padding: 0 17%;
              display: block;
              width: 100%;
            }
            @media (max-width: 768px) {
              &__qty {
                width: 22%;
              }
              &__total {
                width: 20%;
              }
            }
          }
        }
        .total_column.sub__total {
          float: right;
          margin: 12px 0;
        }
        .cart-header {
          .cart-header-sub-container {
            .price {
              width: 20%;
            }
            .total {
              width: 14%;
            }
            @media (max-width: 768px) {
              .qty {
                width: 22%;
              }
              .total {
                width: 20%;
              }
            }
          }
        }
      }
    }
    #viewcart-panel,
    #bottom-viewcart-buttons {
      .viewcart-buttons-panel {
        .continue-buttons {
          .disabled.continue-checkout {
            background-color: #cccccc;
          }
        }
      }
    }
    .checkout-buttons-container {
      .checkout-buttons.disabled {
        background-color: #cccccc;
      }
    }
    .checkout-buttons-container {
      margin-bottom: 60px;
      input.form-submit {
        float: right;
      }
    }
    .shipping-panel {
      #review-address-info {
        .transaction-panel-details {
          margin-top: 20px;
          .transaction-item {
            float: left;
            margin: 0 28px 28px 0;
            position: relative;
            width: 290px;
            word-wrap: break-word;
            &.shipping_container {
              clear: none;
              margin-right: 0;
            }
            &.billing_container {
              margin-right: 0;
            }
            dt {
              a.change_link {
                position: absolute;
                top: 10px;
                right: 0;
              }
            }
          }
        }
      }
    }
    .checkout-header {
      margin: 25px 0;
    }
    #shipping-page-info-panel,
    #billing-address-info-panel {
      #bill_to_shipping_container {
        margin-bottom: 11px;
      }
      #shipping-info-panel,
      #billing-info-panel {
        .address-container {
          float: left;
          margin: 0 4em 0.8em 1.5em;
          width: 15em;
          address {
            font-style: normal;
          }
        }
        .address_controls {
          float: left;
          #choose-address {
            margin-top: 0.8em;
            width: 24em;
          }
        }
      }
      #payment-method {
        h3 {
          font-family: 'Brandon Text Bold';
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          text-indent: 0;
          margin: 4px 0 10px 0;
          padding-bottom: 16px;
          border-bottom: 1px solid #000000;
        }
        #payment-container {
          margin-top: 15px;
          p#select-payment {
            margin-bottom: 15px;
          }
          span {
            margin-bottom: 10px;
            display: block;
          }
          ul#credit-cards {
            li {
              float: left;
              margin-right: 10px;
            }
          }
        }
      }
      #giftwrap-info-panel {
        #gift-options {
          h3 {
            font-family: 'Brandon Text Bold';
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            text-indent: 0;
            margin: 4px 0 10px 0;
            padding-bottom: 16px;
            border-bottom: 1px solid #000000;
          }
          #gift-options-wrapper {
            margin-top: 15px;
            #gift-wrap-img {
              margin-top: 15px;
            }
          }
        }
      }
      #emailpromo-info-panel {
        float: left;
        margin-top: 20px;
      }
    }
    .payment-info-panel {
      .payment_method_container {
        #payment-type-cc {
          margin-bottom: 16px;
        }
        .payment-type-panel {
          .payment-type {
            float: left;
            margin-right: 14px;
            label {
              margin-left: 7px;
            }
          }
        }
      }
    }
    #payment-method {
      margin-top: 20px;
    }
    #emailpromo-info-panel,
    .newsletter-info__fieldset {
      p.newsletter-info__text--provid,
      .newsletter-info__item {
        width: 100%;
      }
    }
  }
  .right.checkout__sidebar {
    .return-link-container {
      margin-bottom: 20px;
    }
    #offer-code-panel {
      height: auto;
      .offer-code-panel__content {
        .offer_code_form_container {
          input.form-submit {
            position: relative;
            top: 0;
          }
          #one-offer-only {
            font-style: normal;
            display: block;
            margin: 18px 0 14px 0;
            .checkout-co-enabled & {
              display: inline-block;
            }
          }
        }
      }
    }
    .tax.value {
      line-height: 19px;
    }
  }
  ol.checkout-progress {
    li {
      float: left;
      margin: 24px 0 0 24px;
      width: 175px;
      list-style-type: decimal;
    }
    .shipping .checkout-progress__shipping,
    .review .checkout-progress__review,
    .billing .checkout-progress__billing,
    .confirm .checkout-progress__confirm {
      font-weight: bold;
    }
  }
  .account-utilities {
    &__section {
      background: $color-gray-light-alt;
      padding: 16px 20px;
    }
    &__header {
      font-family: $brandon-text-bold;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      text-indent: 0;
      margin: 4px 0 10px 0;
      padding-bottom: 17px;
      border-bottom: 1px solid $color-black;
    }
  }
}

#confirm {
  #print-order {
    margin-top: 20px;
  }
  .viewcart.checkout {
    ol.checkout-progress {
      .checkout-progress {
        &__confirm {
          font-weight: bold;
        }
      }
    }
  }
}

#payment {
  .viewcart.checkout {
    ol.checkout-progress {
      .checkout-progress {
        &__billing {
          font-weight: bold;
        }
      }
    }
  }
}

#samples {
  .samples-page {
    #samples-panel {
      .samples-buttons.bottom {
        display: block;
      }
      .left.checkout__content {
        .samples {
          .product.sample-cell {
            ul.sample-sku-list {
              li.sample-sku-item {
                .sample-select-checkbox {
                  display: block !important;
                  visibility: visible;
                }
              }
            }
          }
        }
      }
    }
    .product {
      min-height: 482px;
      &.first {
        margin-left: 0;
      }
    }
  }
}

#review {
  #cboxContent {
    padding: 20px;
    overflow: auto;
  }
  .viewcart.checkout {
    ol.checkout-progress {
      .checkout-progress {
        &__review {
          font-weight: bold;
        }
      }
    }
  }
}

#shipping {
  #address_form_container {
    margin-bottom: 17px;
    .form_element {
      margin-bottom: 6px;
    }
    .title_container,
    .phone_label_container {
      margin-bottom: 10px;
    }
    input[type='text'],
    select {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .address_form_container {
    a.selectBox {
      margin-bottom: 20px;
    }
  }
  .viewcart.checkout {
    ol.checkout-progress {
      .checkout-progress {
        &__shipping {
          font-weight: bold;
        }
      }
    }
  }
}

#recommended-products-panel {
  .recommended-product-items {
    .recommended-item {
      position: relative;
      padding-bottom: 60px;
      .column.item_info {
        position: relative;
        .product_name {
          height: 45px;
        }
        .product_subname {
          height: 20px;
        }
      }
      .add_button {
        position: absolute;
        bottom: 0;
      }
    }
  }
}

#contract_of_sale_container {
  .button {
    margin-bottom: 20px;
  }
}

.sms_promotions {
  margin-bottom: 10px;
  margin-left: 20px;
  .sms_options_container {
    margin: 10px 0;
    .phone_container {
      input {
        margin-top: 5px;
      }
    }
  }
}

.newsletter-info {
  .newsletter-info__fieldset {
    .newsletter-info__text--provid {
      float: left;
      width: 100%;
    }
    .newsletter-info__item {
      width: 100%;
    }
  }
  &__header {
    text-indent: 0;
  }
}

.touch {
  select {
    min-width: 100%;
  }
}

.waitlist-iframe-wrapper {
  padding: 33px 20px 0;
  width: 450px;
  height: 260px;
}

.checkout-co-enabled {
  .checkout {
    &__sidebar {
      .order-summary-panel {
        &__title {
          margin-top: 20px;
        }
      }
      #links-panel {
        .links-panel {
          &__title {
            margin: 0;
          }
        }
      }
      #offer-code-panel {
        .offer-code-panel {
          &__title {
            margin-top: 10px;
          }
        }
      }
    }
    .left {
      &.checkout {
        &__content {
          .checkout-header {
            margin: 0;
          }
          #viewcart-panel {
            .cart-items {
              &.products {
                .cart-item {
                  .product {
                    .total {
                      width: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
