.links-panel {
  display: block !important;
  visibility: visible !important;
  .checkout__sidebar & {
    @include checkout-sidebar-panel;
    margin-top: 6px;
    border: 5px solid $color-nude;
    padding: 15px 15px 20px 15px;
    background: $color-white;
    &__title {
      margin-top: 34px;
    }
  }
  .additional-info {
    margin: 0;
    padding: 0;
    line-height: normal;
  }
  .links_list {
    > li {
      display: block;
      border: 0;
      line-height: 2;
      @include breakpoint($small-down) {
        .overlay-link {
          display: block;
          width: 100%;
          text-align: center;
        }
      }
      @include breakpoint($large-up) {
        text-decoration: none;
      }
      .overlay-link {
        @include l2;
        font-size: 11px;
        font-weight: normal;
        text-decoration: none;
      }
    }
  }
  &__title {
    @include breakpoint($large-up) {
      .checkout__sidebar & {
        @include breakpoint(text--short);
        border-bottom: none;
      }
    }
  }
}
